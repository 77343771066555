/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
@font-face {
  font-family: "Calibri";
  src: url(../font/Calibri.eot);
  src: url(../font/Calibri.eot?#iefix) format("embedded-opentype"),
    url(../font/Calibri.woff) format("woff"),
    url(../font/Calibri.woff2) format("woff2"),
    url(../font/Calibri.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.bg-sync-color {
  --bs-bg-opacity: 1;
  background-color: #e4e6ef !important;
  color: black !important;
}
.group .nav {
  flex-direction: row;
}

/* Filter dropdown */
.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}

.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
  color: #4154f1;
}

.dashboard .filter .dropdown-header {
  padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}

.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

/* Info Cards */
.dashboard .info-card {
  padding-bottom: 10px;
}

.dashboard .info-card h6 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

/* Activity */
.dashboard .activity {
  font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}

.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

.dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}

.dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}

.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* News & Updates */
.dashboard .news .post-item + .post-item {
  margin-top: 15px;
}

.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
}

.dashboard .news h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dashboard .news h4 a {
  color: #012970;
  transition: 0.3s;
}

.dashboard .news h4 a:hover {
  color: #4154f1;
}

.dashboard .news p {
  font-size: 14px;
  color: #777777;
  margin-left: 95px;
}

/* Recent Sales */
.dashboard .recent-sales {
  font-size: 14px;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

.dashboard .recent-sales .table thead th {
  border: 0;
}

.dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px 0;
}

.dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0 0;
}

td.dashboard-permissions {
  max-width: 150px;
}

/* Top Selling */
.dashboard .top-selling {
  font-size: 14px;
}

.dashboard .top-selling .table thead {
  background: #f6f6fe;
}

.dashboard .top-selling .table thead th {
  border: 0;
}

.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

.dashboard .top-selling img {
  border-radius: 5px;
  max-width: 60px;
}

.btn.btn-bg-light {
  border-color: #f5f8fa;
  background-color: #f5f8fa;
}

/* Card */
.section.dashboard {
  padding: 10px 50px 10px;
}

.ps-15 {
  padding-left: 3.75rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.svg-icon {
  line-height: 1;
}

.card {
  border: 0 !important;
  border-radius: 16px !important;
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.1);
  background-color: #ffffff !important;
}

.card .card_background {
  border: 0 !important;
  border-radius: 16px !important;
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.1);
  background-color: #dbf4f5 !important;
}
.card .card_background_gray {
  border: 0 !important;
  border-radius: 16px !important;
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.1);
  background-color: #e4e6ef !important;
}

.section {
  width: calc(100% - 12px);
}

.custom_background div {
  /* background-color: #dbf4f5 !important; */
}

.custom_background nav {
  background-color: #dbf4f5 !important;
  height: 32px !important;
  min-height: 0px !important;
}
.remove_custom_background nav {
  background-color: unset !important;
}

.card_background_nested div {
  background-color: #e4e6ef !important;
}

.card_background_nested nav {
  background-color: #e4e6ef !important;
  height: 32px !important;
  min-height: 0px !important;
}

/* .custom_background .kxyAkh {
  background-color: #dbf4f5 !important;
  height: 32px !important;
  min-height: 0px !important;
}

.custom_background .iLscDm {
  background-color: #dbf4f5 !important;
} */

.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 0px;
  color: var(--kt-card-cap-color);
  background-color: var(--kt-card-cap-bg);
  border-bottom: 1px solid var(--kt-card-border-color);
}

.card .card-header .card-title {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}

.card .card-header .card-title.flex-column {
  align-items: flex-start;
  justify-content: center;
}

.card .card-header .card-title .card-icon {
  margin-right: 0.75rem;
  line-height: 0;
}

.card .card-header .card-title .card-icon i {
  font-size: 1.25rem;
  color: var(--kt-gray-600);
  line-height: 0;
}

.card .card-header .card-title .card-icon i:after,
.card .card-header .card-title .card-icon i:before {
  line-height: 0;
}

.card .card-header .card-title .card-icon .svg-icon {
  color: var(--kt-gray-600);
}

.card .card-header .card-title .card-icon .svg-icon svg {
  height: 24px;
  width: 24px;
}

.card .card-header .card-title,
.card .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 20px !important;
  color: var(--kt-text-dark);
}

.card .card-header .card-title .card-label {
  margin: 0 0.75rem 0 0;
  flex-wrap: wrap;
}

.card .card-header .card-title small,
.card .card-header .card-title .small {
  color: var(--kt-text-muted);
  font-size: 1rem;
}

.card .card-header .card-title h1,
.card .card-header .card-title .h1,
.card .card-header .card-title h2,
.card .card-header .card-title .h2,
.card .card-header .card-title h3,
.card .card-header .card-title .h3,
.card .card-header .card-title h4,
.card .card-header .card-title .h4,
.card .card-header .card-title h5,
.card .card-header .card-title .h5,
.card .card-header .card-title h6,
.card .card-header .card-title .h6 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 800;
}

.text-muted {
  font-size: 12px;
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: red;
  padding-left: 0.25rem;
  font-weight: 600;
}

.form-control.form-control-solid {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  /* border-radius: 6px !important; */
  color: #5e6278;
  transition: color 0.2s ease;
  height: 40px;
  font-size: 12px;
}

.form-control.form-control-solid:focus {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease;
  box-shadow: none !important;
}

.card .card-header .card-toolbar {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}

.card .card-body {
  padding: 2rem 2.25rem;
  color: var(--kt-card-color);
}

.card .card-footer {
  padding: 2rem 2.25rem;
  color: var(--kt-card-cap-color);
  background-color: var(--kt-card-cap-bg);
  border-top: 1px solid var(--kt-card-border-color);
}

.card .card-scroll {
  position: relative;
  overflow: auto;
}

.card.card-px-0 .card-header,
.card.card-px-0 .card-body,
.card.card-px-0 .card-footer {
  padding-left: 0;
  padding-right: 0;
}

.card.card-py-0 .card-header,
.card.card-py-0 .card-body,
.card.card-py-0 .card-footer {
  padding-top: 0;
  padding-bottom: 0;
}

.card.card-p-0 .card-header,
.card.card-p-0 .card-body,
.card.card-p-0 .card-footer {
  padding: 0;
}

.card.card-dashed {
  box-shadow: none;
  border: 1px dashed #e4e6ef;
}

.card.card-dashed > .card-header {
  border-bottom: 1px dashed#E4E6EF;
}

.card.card-dashed > .card-footer {
  border-top: 1px dashed#E4E6EF;
}

.card.card-bordered {
  box-shadow: none;
  border: 1px solid#E4E6EF;
}

.card.card-flush > .card-header {
  border-bottom: 0 !important;
}

.card.card-flush > .card-footer {
  border-top: 0 !important;
}

.card.card-shadow {
  box-shadow: var(--kt-card-box-shadow);
  border: 0;
}

.card.card-reset {
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.card.card-reset > .card-header {
  border-bottom: 0 !important;
}

.card.card-reset > .card-footer {
  border-top: 0 !important;
}

.card.card-stretch {
  height: calc(100% - var(--bs-gutter-y));
}

.card.card-stretch-75 {
  height: calc(75% - var(--bs-gutter-y));
}

.card.card-stretch-50 {
  height: calc(50% - var(--bs-gutter-y));
}

.card.card-stretch-33 {
  height: calc(33.333% - var(--bs-gutter-y));
}

.card.card-stretch-25 {
  height: calc(25% - var(--bs-gutter-y));
}

.card .card-header-stretch {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  align-items: stretch;
}

.card .card-header-stretch .card-toolbar {
  margin: 0;
  align-items: stretch;
}

.card-p {
  padding: 2rem 2.25rem !important;
}

.card-px {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.card-shadow {
  box-shadow: var(--kt-card-box-shadow);
}

.card-py {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.card-rounded {
  border-radius: 0.625rem;
}

.card-rounded-start {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.card-rounded-end {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.card-rounded-top {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.card-rounded-bottom {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.symbol.symbol-45px.me-5 img {
  width: 45px;
  height: 45px;
}

.dashboardtable .table > :not(:first-child) {
  border-color: transparent;
  border-width: 0;
  border-style: none;
}

.dashboardtable .table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}

.dashboardtable .table tr,
.dashboardtable .table th,
.dashboardtable .table td {
  border-color: inherit;
  border-width: inherit;
  border-style: inherit;
  text-transform: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  height: inherit;
  min-height: inherit;
}

.dashboardtable .table tr:first-child,
.dashboardtable .table th:first-child,
.dashboardtable .table td:first-child {
  padding-left: 0;
}

.dashboardtable .table tr:last-child,
.dashboardtable .table th:last-child,
.dashboardtable .table td:last-child {
  padding-right: 0;
}

.dashboardtable .table tfoot tr:last-child,
.dashboardtable .table tbody tr:last-child {
  border-bottom: 0 !important;
}

.dashboardtable .table tfoot tr:last-child th,
.dashboardtable .table tfoot tr:last-child td,
.dashboardtable .table tbody tr:last-child th,
.dashboardtable .table tbody tr:last-child td {
  border-bottom: 0 !important;
}

.dashboardtable .table tfoot th,
.dashboardtable .table tfoot td {
  border-top: inherit;
}

.dashboardtable .table .table-rounded {
  border-radius: 0.475rem;
  border-spacing: 0;
  border-collapse: separate;
}

.dashboardtable .table.table-flush tr,
.dashboardtable.table.table-flush th,
.dashboardtable.table.table-flush td {
  padding: inherit;
}

.dashboardtable .table.table-row-bordered tr {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--kt-border-color);
}

.dashboardtable .table.table-row-bordered tfoot th,
.dashboardtable .table.table-row-bordered tfoot td {
  border-top-width: 1px !important;
}

.dashboardtable .table.table-row-dashed tr {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: #e4e6ef;
}

.dashboardtable .table.table-row-dashed tfoot th,
.dashboardtable .table.table-row-dashed tfoot td {
  border-top-width: 1px !important;
}

.dashboardtable .table.gy-4 th,
.table.gy-4 td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.svg-icon.svg-icon-3 svg {
  font-size: 16px;
}

.rct-node-icon {
  color: #176c89 !important;
}

.btn.btn-light.btn-active-primary {
  color: #fff;
  border-color: #1ba1a7;
  background-color: #1ba1a7 !important;
  padding: 4px 8px 4px 8px;
}

.btn.btn-light.btn-active-secondary {
  color: #fff;
  border-color: #8aa0a8ad;
  background-color: #8aa0a8ad !important;
}

.btn .plus-svg-icon {
  flex-shrink: 0;
  line-height: 0;
  margin-right: 0.5rem;
}

.btn.btn-link {
  border: 0;
  border-radius: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-decoration: none;
  font-weight: 500;
}

.btn.btn-outline:not(.btn-outline-dashed) {
  border: 1px solid #e4e6ef;
}

.btn.btn-outline-dashed {
  border: 1px dashed #e4e6ef;
}

.btn.btn-flush {
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none !important;
  margin: 0;
  padding: 0;
}

.btn.btn-flex {
  display: inline-flex;
  align-items: center;
}

.btn.btn-trim-start {
  justify-content: flex-start !important;
  padding-left: 0 !important;
}

.btn.btn-trim-end {
  justify-content: flex-end !important;
  padding-right: 0 !important;
}

.createBtn a {
  background-color: #0397a7;
  border-color: #0397a7;
}

.createBtn a:hover {
  background-color: #0397a7;
  border-color: #0397a7;
}

/* Alerts */
.alert-heading {
  font-weight: 500;
  font-family: "Calibri";
  font-size: 20px;
}

/* Close Button */
.btn-close {
  background-size: 25%;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Breadcrumbs */
.breadcrumb {
  font-size: 14px;
  font-family: "Calibri";
  color: #899bbd;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
  text-decoration: none;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

/* Data Table */
.dashboardtable .table {
  border-spacing: 0 0.85rem !important;
}

.dashboardtable .table .dropdown {
  display: inline-block;
}

.dashboardtable .table td,
.dashboardtable .table th {
  vertical-align: middle;
  margin-bottom: 10px;
  border: none;
}

.dashboardtable .table thead tr,
.dashboardtable .table thead th {
  border: none;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: transparent;
}

.dashboardtable .table td {
  background: #fff;
}

.dashboardtable .table td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.dashboardtable .table td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.avatar {
  width: 2.75rem;
  height: 2.75rem;
  line-height: 3rem;
  border-radius: 50%;
  display: inline-block;
  background: transparent;
  position: relative;
  text-align: center;
  color: #868e96;
  font-weight: 700;
  vertical-align: bottom;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.83333rem;
  line-height: 1.5;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-blue {
  background-color: #c8d9f1;
  color: #467fcf;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  top: 28px;
  left: 14px;
  border: none;
  box-shadow: none;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  padding-left: 48px;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  width: 100%;
}

table.dataTable > tbody > tr.child span.dtr-title {
  min-width: 50%;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  padding: 0.75rem 1rem 0.125rem;
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
  margin-bottom: 0;
}

.btn-icon {
  background: #fff;
}

.btn-icon .bx {
  font-size: 20px;
}

.btn .bx {
  vertical-align: middle;
  font-size: 20px;
}

.dropdown-menu {
  padding: 0.25rem 0;
}

.dropdown-item {
  padding: 0.5rem 1rem;
}

.badge {
  padding: 0.5em 0.75em;
  margin: 4px;
}

.badge-success-alt {
  background-color: #d7f2c2;
  color: #7bd235;
}

.badge-light-primary {
  color: #009ef7;
  background-color: #f1faff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 8px 6px 10px;
}

.badge-light-danger {
  color: #f1416c;
  background-color: #fff5f8;
}

.dashboardtable a {
  color: #6ba2b6;
  text-decoration: none;
}

.dashboardtable a:hover,
.dashboardtable a:focus {
  text-decoration: none;
}

.job-monitor-table-wrapper button,
.job-monitor-table-wrapper a {
  background-color: transparent;
  color: #1c858a;
}

table.dataTable {
  margin-top: 12px !important;
}

.icon > .bx {
  display: block;
  min-width: 1.5em;
  min-height: 1.5em;
  text-align: center;
  font-size: 1.0625rem;
}

.btn {
  font-size: 0.9375rem;
  font-weight: 500;
}

.avatar-blue {
  background-color: #c8d9f1;
  color: #467fcf;
}

.avatar-pink {
  background-color: #fcd3e1;
  color: #f66d9b;
}

.powerbtn {
  height: 1.25rem !important;
  width: 1.25rem !important;
}

/* Toggle Sidebar */

/*===== l NAV =====*/
.l-navbar {
  /* position: fixed;
  top: 0;
  left: 0;
  width: calc(92px + 13.25rem);
  height: 100vh;
  box-shadow: 0px 0px 20px rgb(1 41 112 / 10%);
  background-color: #e4e6ef;
  color: #fff;
  padding: 1.5rem 1.5rem 2rem;
  transition: .5s; */
  /* z-index: 100; */
  /* padding: 0; */
  transition: 0.5s;
  z-index: 100;
  width: calc(92px + 10.25rem);
  padding-bottom: 40px;
  color: #fff;
  /* height: 100vh; */
  left: 0;
  padding: 1.5rem 1rem 2rem;
  position: fixed;
  top: 60px;
  transition: 0.5s;
  /* display: flex;
  width: 100%; */
}

/* .aside-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
} */

/*===== NAV =====*/
.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow-y: scroll !important;
}

/* .nav-padding {
  padding-bottom: 16vw;

} */

.nav::-webkit-scrollbar {
  display: none;
}

/*Expander menu*/
.expander {
  height: 95vh;
  overflow-y: scroll;
  width: 76px;
  white-space: nowrap;
}

/* .expander:hover {
  width: 264px;
} */

.expander .log_out_btn {
  margin-bottom: 30px;
}

.expander .synced_data {
  display: none !important;
}

/*Add padding body*/
.body-pd {
  padding: 2rem 0 0 16rem;
}

header.sc-dIfARi.kDEcCH {
  justify-content: flex-start;
  display: none;
}

/* Time Picker */
.react-time-picker__wrapper {
  border: 0 !important;
}

.footer {
  position: fixed;
  z-index: 999999;
  /* border-radius: 10px; */
  background-color: transparent;
  bottom: 0;
  /* width: 100%; */
  font-size: 14px;
  transition: all 0.3s;
  /* box-shadow: 0px -2px 20px rgb(1 41 112 / 10%); */
}

.footer .copyright {
  text-align: center;
  color: #012970;
}

.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}

.css-1s2u09g-control {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  color: #5e6278 !important;
  height: 40px !important;
  border-radius: 10px !important;
  transition: color 0.2s ease !important;
}

.css-1pahdxg-control {
  border-color: #f5f8fa !important;
  box-shadow: 0 0 0 2.25px rgb(107 162 182) !important;
}

.form-switch .form-check-input {
  font-size: 16px;
  border-radius: 2rem !important;
  /* margin-top: 8px; */
  margin-left: -36px;
}

.form-check-input:checked {
  background-color: #6ba2b6;
  border-color: #6ba2b6;
}

/* .css-1s2u09g-control:focus{
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  color: #5E6278 !important;
  transition: color 0.2s ease !important;
  box-shadow: 0 0 0 2.25px rgb(107 162 182) !important;
} */
.react-tabs {
  width: 100%;
  position: fixed !important;
  bottom: 0 !important;
  z-index: 9999999999 !important;
}

.dashboard_togglebtn {
  box-shadow: 0px 0px 10px rgb(113 121 136 / 10%);
  background-color: #fff;
  padding: 4px 8px 4px;
  border-radius: 10px;
}

.dashboard_togglebtn span :hover {
  color: #6ba2b6 !important;
}

.react-date-picker__wrapper {
  height: 40px !important;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  border-radius: 10px !important;
  color: #5e6278 !important;
  transition: color 0.2s ease;
}

.react-date-picker {
  width: 100%;
}

.form-check-input[type="radio"] {
  border-radius: 50% !important;
}

.form-check-input[type="checkbox"] {
  border-radius: 5px !important;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #dee2e6;
}

.border-top-dashed {
  border-top: 1px dashed #dee2e6;
}

.clr-heading {
  color: #1ba1a7;
}

.form-switch-custom {
  margin-top: 5px;
}

.reasonCode-search > .form-control::placeholder {
  font-size: 14px;
}

.payee-search > .form-control::placeholder {
  font-size: 14px;
}

.payer-search > .form-control::placeholder {
  font-size: 14px;
}

.jobMonitor-Buttons {
  width: 32px;
  height: 32px;
  padding: 5px;
}

.jobMonitor-svg {
  transform: scale(1);
}

.jobMonitor-Release-Buttons {
  width: 60px;
  height: 37px;
  padding: 5px;
}

.jobMonitor-Release-span {
  font-size: 12px;
}

/* .correspondenceDatePicker {
  margin-top: 5px;
} */

.swal2-popup {
  width: 25em !important;
}

.swal2-title {
  padding: 0px !important;
  color: #6ba2b6 !important;
  font-weight: 700 !important;
}

.swal2-close:hover {
  color: #6ba2b6 !important;
}

.outputSchema-search {
  width: 220px;
  font-size: 14px;
  height: 38px !important;
  padding: 2px 8px !important;
}

.btnHover {
  background-color: #6ba2b6 !important;
  border-color: #6ba2b6 !important;
}

.btnHover:hover {
  background-color: #176c89 !important;
  border-color: #176c89 !important;
}
