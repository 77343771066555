@font-face {
  font-family: "Calibri";
  src: url(../font/Calibri.eot);
  src: url(../font/Calibri.eot?#iefix) format("embedded-opentype"),
    url(../font/Calibri.woff) format("woff"),
    url(../font/Calibri.woff2) format("woff2"),
    url(../font/Calibri.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html * {
  scroll-behavior: smooth !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.h-100vh {
  height: 100vh !important;
}

.ms-10 {
  margin-left: 100px;
}

.b-r-10 {
  border-radius: 10px !important;
}

.b-r-50 {
  border-radius: 50% !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-t {
  border: 1px solid #d6eced !important;
  border-radius: 8px 8px 0 0 !important;
}

.b-r-b {
  border-radius: 0 0 8px 8px !important;
  border: 1px solid #d6eced !important;
}

.fs-7 {
  font-size: 0.7em !important;
}

.w-50 {
  width: 50vw;
}

.w-75 {
  width: 75vw;
}

.w-60 {
  width: 60%;
}

.loginHeader {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.loginPage-header {
  padding: 10px;
}

.loginheaderLogo {
  padding: 10px 20px;
}

.loginheaderLogo img {
  width: 90% !important;
}

.loginForm {
  font-family: "Calibri" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* .loginForm img {
    width: 100%
} */

.logFont {
  /* margin-top: 70px; */
  margin-left: 126px;
}

/* .logFont h1 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 50px
} */

.buttonLogin button {
  background-color: #176c89;
  border-color: #fff;
  padding: 10px 20px;
  width: 100%;
}

.buttonLogin button:hover {
  background-color: #1480a5;
  border-color: #fff;
}

.buttonLogin button:active {
  background-color: #6ba2b6 !important;
  border-color: #fff !important;
}

.form-floating .form-control:focus {
  border-color: none;
  box-shadow: none;
}

.error-field {
  /* background-color: #ff8686bd !important */
}
