@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(../font/password.ttf);
}
@font-face {
  font-family: "Calibri";
  src: url(../font/Calibri.eot);
  src: url(../font/Calibri.eot?#iefix) format("embedded-opentype"),
    url(../font/Calibri.woff) format("woff"),
    url(../font/Calibri.woff2) format("woff2"),
    url(../font/Calibri.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
input[type="login-password"] {
  /* color: #0fd205 !important; */
  font-family: "password" !important;
  -webkit-text-security: disc !important;
  writing-mode: horizontal-tb !important;
  padding-block: 1px;
  padding-inline: 2px;
  /* font-size: 10px !important; */
  letter-spacing: 1px !important;
}
.App {
  font-family: "Calibri";
  text-align: center;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

.bi-download {
  color: #6ba2b6 !important;
}

.color-xtract {
  color: #6ba2b6 !important;
}

.Logo {
  padding: 2px 2px 2px;
}

.Logo img {
  inline-size: 90px !important;
}

.dbk-main-container {
  display: inline-flex;
  position: relative;
  inline-size: 100%;
}

.dbk-main-container input {
  display: block !important;
  width: 98% !important;
}

.dbk-main-container .dbk-date {
  width: 96% !important;
}
input[data-corress-disable="true"] {
  background-color: #aeb2b5 !important;
}

select[data-corress-disable="true"] {
  background-color: #aeb2b5 !important;
}

.dbk-main-container1 {
  display: inline-flex;
  position: relative;
  inline-size: 100%;
}

.dbk-main-container1 input {
  display: block !important;
  width: 98% !important;
}

.dbk-main-container1 .dbk-date {
  width: 96% !important;
}

.rpv-default-layout__toolbar {
  color: #fff;
  background-color: #0397a7 !important;
  block-size: 30px !important;
}

.custome-invalid-feedback {
  display: flex;
  width: 85%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #176c89;
}

.rpv-core__icon {
  stroke: #fff !important;
  stroke-width: 3 !important;
}

#dbk-tab-container .nav-link.active {
  background-color: #6ba2b6 !important;
  color: #ffffff !important;
}

#dbk-tab-container .nav-link {
  color: #0e0e0e !important;
}

a:hover {
  text-decoration: none !important;
}

.rpv-zoom__popover-target {
  color: #fff !important;
}

.rpv-zoom__popover-target-arrow {
  border-color: rgb(255 255 255 / 60%) transparent transparent !important;
}

.rpv-default-layout__sidebar-headers {
  background-color: #0397a7 !important;
  padding: 0px 1px !important;
}

.rpv-thumbnail__cover {
  /* height: 90% !important; */
  inline-size: 1500px !important;
  block-size: 140% !important;
  overflow-y: scroll !important;
}

.rpv-thumbnail__cover-inner {
  display: block !important;
  inline-size: 1500px !important;
}

.rpv-thumbnail__cover-image {
  /* height: 2000px !important; */
  width: 1000px !important;
  max-height: 170% !important;
  padding-bottom: 200px;
}

.rpv-default-layout__sidebar {
  padding-top: 30px !important;
}

.rpv-core__textbox {
  height: 20px !important;
}

.viewer-layout-toolbar {
  height: 24px;
  background-color: #0397a7 !important;
  padding: 0px !important;
}

.viewer-button {
  padding: 0px !important;
}

.viewer-icon {
  width: 15px !important;
  height: 15px !important;
  stroke: #fff !important;
  stroke-width: 3 !important;
}

.rpv-core__icon {
  width: 12px !important;
  height: 12px !important;
}

svg.viewer-icon {
  width: 10px !important;
  height: 10px !important;
}

.viewer-zoom-popover-target {
  color: #fff !important;
  padding: 0px 8px 0px !important;
}

.viewer-zoom-popover-target-arrow {
  border-color: rgb(255 255 255 / 60%) transparent transparent !important;
}

.viewer-zoom-popover-target-scale {
  font-size: 12px !important;
}

.viewer-sidebar-thumbnails {
  display: inline !important;
}

.viewer-thumbnail-selected {
  background-color: transparent !important;
}

.viewer-sidebar-tabs {
  display: none !important;
  padding: 0px !important;
}

input.viewer-toolbar-current-page-input {
  height: 18px !important;
  width: 30px !important;
  font-weight: 900;
}

.viewer-layout-with-sidebar .viewer-layout-sidebar {
  display: flex;
  width: 180px;
}

.viewer-layout-container {
  height: 950px !important;
}

.viewer-inner-page {
  display: flex;
  justify-content: center;
}

.viewer-thumbnail-container {
  width: 130px !important;
}

.viewer-thumbnail-container img {
  width: 140px !important;
}

/* GRID */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Raleway:wght@700&display=swap");

body {
  font-family: "Calibri" !important;

  overflow-x: hidden !important;
}
.dashboardtable {
  /* font-family: "Poppins", sans-serif !important; */
}
::-webkit-scrollbar {
  inline-size: 8px;
}

::-webkit-scrollbar-track {
  background-color: #dee2e6;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c7ce;
  border-radius: 4px;
  border: 1px solid transparent;
  background-clip: content-box;
}

div.ex2 {
  position: sticky;
  inline-size: 100%;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.grid-fixed-pos {
  position: sticky;
  inline-size: 100%;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

div.ex2::-webkit-scrollbar {
  display: none !important;
}

div.ex2::-webkit-scrollbar-track {
  background-color: none !important;
}

div.ex2::-webkit-scrollbar-thumb {
  background-color: none !important;
}

div.ex2::-webkit-scrollbar-track {
  background-color: none !important;
}

.dflex {
  display: flex;
}

.ml-40 {
  margin-left: 40px;
}

/* */
.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap !important;
  width: 100%;
  width: 75%;
  height: 18px;
  margin-top: 10px;
}

.input-group-client-password {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap !important;
  width: 100%;
  width: 75%;
  height: 18px;
  margin-top: 0px !important;
}

.claims-password-input {
  margin-top: 0px;
}

.input-group-text {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #5b869e !important;
  border-radius: 4px !important;
}

.input-group-text-password {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #f2f2f2 !important;
  border-radius: 4px !important;
}

.input-group-text-password-client {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #f2f2f2 !important;
  border-radius: 4px !important;
}

.input-group-text,
.form-select,
.form-control,
input,
select {
  border-radius: 0 !important;
}

.selc-bg-1 {
  background-color: #c1e2de !important;
  font-size: 12px !important;
  padding: 0px !important;
  border-radius: 5px !important;
}

.select-pan {
  font-size: 12px !important;
  padding: 0px !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
  width: 10% !important;
}

.selc-bg-2 {
  background-color: #c1e2de !important;
  font-size: 12px !important;
  border-radius: 5px !important;
  padding: 0px !important;
}

/* Width */
.paymentmethod-wi {
  width: 22%;
}

.checknum-wi {
  width: 257px;
}

.checkdate-wi {
  width: 180px;
}

.checkamt-wi {
  width: 257px;
}

.remainingbln-wi {
  width: 14%;
}

.ChkPgNo-wi {
  width: 8%;
}

.billed-wrapper {
  width: 204px;
}

.paid-wrapper {
  width: 200px;
}

.overall-from {
  margin-top: 2px !important;
}

.pagefrom {
  width: 40px !important;
}

.pagefrom-corress {
  width: 130px !important;
}

.pageto-corress {
  width: 130px !important;
}

.page-claim-from {
  width: 40px !important;
}

.pageto {
  width: 40px !important;
}

.page-claim-to {
  width: 40px !important;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #fff !important;
  /* background-color: transparent !important;
    border-color: transparent !important; */
}

.grid {
  border: 5px solid white !important;
  background-color: #dae2e6 !important;
  min-width: 1900px !important;
  max-width: 1920px !important;
}

.bg-color {
  max-height: 405px !important;
  min-height: 182px !important;
  /* /* height: 405px !important; */
  overflow: auto !important;
}

.bg-color-pap {
  max-height: 405px !important;
  min-height: 182px !important;
  /* min-width: 1890px !important;
    max-width: 1920px !important; */
  overflow: scroll !important;
}

.bg-color-lite {
  max-height: 265px !important;
  min-height: 182px !important;
  /* min-width: 1890px !important;
    max-width: 1920px !important; */
  overflow: scroll !important;
}

.p-common {
  padding: 0px 10px 2px 10px !important;
  z-index: 0;
}
.bg-color-pp {
  max-height: 310px !important;
  min-height: 182px !important;
  /* min-width: 1890px !important;
    max-width: 1920px !important; */
  overflow: scroll !important;
}
.lookup-lite {
  max-height: 405px !important;
  height: 400px;
  min-height: 182px !important;
  /* min-width: 1890px !important;
    max-width: 1920px !important; */
  overflow: scroll !important;
}

.claim-summary-header {
  max-height: 405px !important;
  height: 400px;
  min-height: 182px !important;
  /* min-width: 1890px !important;
    max-width: 1920px !important; */
  overflow: scroll !important;
}

.healthcare {
  padding: 2px 10px 2px !important;
  background-color: #6ba2b6 !important;
}

.serviceline {
  padding: 2px 10px 2px;
  background-color: #6ba2b6 !important;
}

.healthcare-sub {
  padding: 2px 10px 2px;
  background-color: #6ba2b6 !important;
}

.healthcare-top {
  display: flex !important;
  gap: 20px !important;
  text-transform: uppercase !important;
  justify-content: space-between !important;
}

.leftside-block {
  display: flex;
  gap: 20px;
}

.rightside-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.serviceline-top {
  display: flex;
  gap: 30px;
  text-transform: uppercase;
  justify-content: space-between;
}

.payersearch img {
  width: 13px;
  height: 13px;
}

.payersearch {
  cursor: pointer;
}

.btn.btn-healthcare {
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
  padding: 0px 4px 0px !important;
  margin: 0 !important;
}

.btn.btn-healthcare img {
  margin-top: -3px;
}

.btn.btn-healthcare img.clear {
  width: 15px;
}

.serviceline-sub-section {
  display: flex;
  padding: 2px 0px;
  margin-left: 20px;
}

.btn.btn-healthcare:hover {
  color: #fff;
}

.btn.btn-healthcareokl:hover {
  color: #5b869e !important;
  background-color: #fff !important;
}

.btn.btn-healthcareokl {
  color: #5b869e !important;
  background-color: #fff !important;
  border-radius: 2px !important;
  height: 18px !important;
  padding: 0px 14px 0px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

.btn.btn-reject:hover {
  color: #5b869e !important;
  background-color: #fff !important;
}

.btn.btn-reject {
  color: #5b869e !important;
  background-color: #fff !important;
  border-radius: 2px !important;
  height: 18px !important;
  padding: 0px 14px 0px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

.btn.btn-checkview {
  color: #5b869e;
  background-color: #fff;
  border-radius: 2px;
  height: 18px;
  padding: 0px 8px 0px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.btn.btn-checkview:hover {
  color: #5b869e !important;
  background-color: #fff !important;
}

.btn.btn-payment835 {
  background: #99c7d7;
  border-radius: 0;
  padding: 0px 15px 0px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
  margin-right: 10px;
  min-width: 150px;
  text-align: left;
}

.btn.btn-payment835:hover {
  background: #99c7d7 !important;
}

.btn.btn-payer {
  background: #99c7d7;
  border-radius: 0;
  padding: 0px 15px 0px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
  margin-right: 10px;
  text-align: left;
  min-width: 150px;
}

.btn.btn-payer:hover {
  background: #99c7d7 !important;
}

.btn.btn-claim {
  background: #99c7d7;
  border-radius: 0;
  padding: 0px 15px 0px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 8px;
  margin-right: 10px;
  text-align: left;
  min-width: 170px;
}

.label-claim {
  background: #99c7d7;
  border-radius: 0;
  padding: 0px 15px 0px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 8px;
  margin-right: 10px;
  text-align: left;
  min-width: 170px;
}

.label-claim-amount {
  background: #99c7d7;
  border-radius: 0;
  padding: 0px 15px 0px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 8px;
  margin-right: 10px;
  text-align: left;
  min-width: 110px;
}

.btn.btn-claim:hover {
  background: #99c7d7 !important;
}

.right-btn {
  margin-left: 23%;
}

.claim835-mt {
  margin-top: 4px;
}

.serviceline-mt {
  margin-top: 4px;
}

.checkviewbtn {
  text-align: center;
  margin: 10px;
}

.checkviewbtn button {
  background-color: #0397a7;
  border-color: #0397a7;
}

.checkviewbtn button:hover {
  background-color: #6ba2b6;
  border-color: #6ba2b6;
}

.label-healthcare {
  font-size: 12px;
  font-weight: 700;
  color: #5b869e;
  display: block;
}

.wrapper {
  width: 200px;
}

.wrapper-statement-date {
  width: 200px;
}

.wrapper-reaminingblnc {
  display: flex;
  margin-top: 18px;
}

.wrapper-patient {
  width: 270px;
}

.wrapper-patient-payment {
  width: 270px;
}

.wrapper-patient-claim {
  width: 240px;
}

.wrapper-rendering {
  width: 24%;
}

.wrapper-miname {
  width: 220px;
  position: relative;
}

.wrap {
  width: 8%;
}

.wrap-pan {
  width: 28%;
}

.wrap-amt {
  width: 13% !important;
}

.serviceline-wrap {
  display: flex;
  margin-left: 90px;
}

.text-wrap {
  width: 6%;
  margin-left: 4%;
}

.text-wrap-sub {
  width: 6%;
  margin-left: 4%;
}

.wrapper-payer {
  width: 24%;
}

.wrapper-add {
  width: 12%;
}

.wrapper-reason {
  width: 7%;
}

.wrapper-ident {
  width: 15%;
}

.w-select,
.w-date,
.w-78,
.w-82,
.w-npi,
.w-70,
.w-40,
.w-30,
.w-30-rmk,
.w-address,
.w-payer,
.w-93,
.w-80,
.w-48,
.w-blnc,
.w-claim-patient,
.w-patient,
.patient-date,
.w-fsname,
.w-miname,
.serviceline-btn,
.serviceline-date,
.quickcalc-btn,
.rpv-core__textbox:focus {
  outline: none;
}

.w-select {
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #c1e2de !important;
  width: 94%;
  height: 18px;
  color: #000 !important;
  font-size: 12px;
}

.w-select-40 {
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #c1e2de !important;
  color: #000 !important;
  width: 40px;
  color: #000 !important;
  height: 20px;
  height: 20px;
  font-size: 12px;
  margin-left: 4px;
}

.w-select-60 {
  border: 1px solid #ced4da !important;
  background-color: #c1e2de !important;
  width: 60px !important;
  height: 20px !important;
  font-size: 12px !important;
  margin-left: 4px !important;
  font-size: 12px !important;
  color: #000 !important;
  padding: 0px !important;
  border-radius: 5px !important;
}

.w-date {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #c1e2de !important;
  color: #000 !important;
  height: 18px !important;
  padding: 0 6px !important;
  font-size: 12px !important;
}

.patient-date {
  border: 1px solid #ced4da;
  border-radius: 4px !important;
  width: 50% !important;
  height: 18px !important;
  padding: 0 6px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.serviceline-date {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 90px !important;
  height: 20px !important;
  color: #000 !important;
  padding: 0 6px !important;
  font-size: 12px !important;
}

.w-30 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  width: 36px !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
  margin-left: 4px !important;
}

.w-30-rmk {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  width: 47px !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
  margin-left: 4px !important;
}

.form-control {
  display: inline !important;
}

.w-40 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #fff;
  color: #000 !important;
  width: 60px !important;
  height: 18px !important;
  line-height: 22px !important;
  font-size: 12px !important;
  padding: 0px 5px 0px 5px !important;
}

.w-48 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #c1e2de !important;
  color: #000 !important;
  width: 48% !important;
  height: 18px !important;
  padding: 0px 5px 0px 5px !important;
  font-size: 12px !important;
}

.w-100 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  width: 100px !important;
  padding: 0px 5px 0px 5px !important;
  height: 18px !important;
  color: #000 !important;
  line-height: 22px !important;
  font-size: 12px !important;
}

.w-70 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  color: #000 !important;
  width: 70px !important;
  padding: 0px 5px 0px 5px !important;
  height: 18px !important;
  line-height: 22px !important;
  font-size: 12px !important;
}

.w-78 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #c1e2de !important;
  height: 18px !important;
  color: #000 !important;
  padding: 0px 5px 0px 5px !important;
  font-size: 12px !important;
}

.w-patient {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #c1e2de !important;
  width: 94% !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.w-mrn {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 94% !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.w-statement-number {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 94% !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.w-claim-patient {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #c1e2de !important;
  width: 94% !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.w-fsname {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 94% !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 14px !important;
}

.w-fsname-corress {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 94% !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 14px !important;
}

.w-miname {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 92% !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 14px !important;
}

.w-npi {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 43% !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.w-82 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 91% !important;
  color: #000 !important;
  height: 18px !important;
  font-size: 12px !important;
}

.w-units {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 91% !important;
  color: #000 !important;
  height: 18px !important;
  font-size: 12px !important;
}

.w-billed {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 91% !important;
  color: #000 !important;
  height: 18px !important;
  font-size: 12px !important;
}

.error-837 {
  background-color: #65a1b4 !important;
  -webkit-box-shadow: 0px 0px 0px 2px rgb(255 134 134);
  -moz-box-shadow: 0px 0px 0px 2px rgb(255 134 134);
  box-shadow: 0px 0px 0px 2px rgb(255 134 134);
}

.data-837 {
  background-color: #65a1b4 !important;
}

.w-80 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #c1e2de !important;
  width: 91% !important;
  color: #000 !important;
  height: 18px !important;
  font-size: 12px !important;
}

.w-blnc {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  color: #000 !important;
  background-color: #ddf1d9 !important;
  width: 91% !important;
  height: 18px !important;
  font-size: 12px !important;
}

.serviceline-btn {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 80px !important;
  height: 18px !important;
  color: #000 !important;
  background-color: #c1e2de !important;
  font-size: 12px !important;
}

.serviceline-btn-837 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  width: 80px !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.w-93 {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.w-payer {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  color: #000 !important;
  width: 97% !important;
  height: 18px !important;
  font-size: 12px !important;
}

.w-pat-a-n {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  color: #000 !important;
  width: 70% !important;
  height: 18px !important;
  font-size: 12px !important;
}

.w-address {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  color: #000 !important;
  width: 96% !important;
  height: 18px !important;
  font-size: 12px !important;
}

.healthcare-form {
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 56%;
}

.sub-section {
  display: flex;
  /* padding: 2px 0px; */
  gap: 4px;
}

.payer-pad {
  margin: 0 5px;
}

.pagenum {
  font-size: 14px;
  font-weight: 600;
  margin-left: 16%;
  margin-top: 3px;
}

.pagenum-sub {
  font-size: 14px;
  font-weight: 600;
}

.draggable {
  position: fixed;
  /* left: 0; */
  /* top: 50%; */
  cursor: grab;
  color: black;
  max-width: 100%;
  border-radius: 5px;
  /* padding: 1em; */
  margin: auto;
  user-select: none;
}

.dropdown-menu {
  border-radius: 0px !important;
  /* inset: 5px auto auto 0px !important; */
}

.dropdown-item {
  text-transform: uppercase;
  font-size: 12px;
  color: #000 !important;
  font-weight: 700 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ced4da !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.btn-outline-danger {
  color: #212529 !important;
  font-weight: 900 !important;
  border-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 0px !important;
}

.btn-outline-danger:hover {
  color: #212529 !important;
  background-color: transparent !important;
  border-color: none !important;
}

.btn.accordion-icon {
  position: relative;
  padding-left: 12px !important;
  box-shadow: none !important;
  height: 23px;
}

.btn.accordion-icon-top {
  position: relative;
  padding-left: 12px !important;
  box-shadow: none !important;
  width: 20px;
  height: 20px;
}

.accordion-icon-top:not(.collapsed)::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  background-image: url("../img/minus-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}

.accordion-icon-top.collapsed::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  background-image: url("../img/plus-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}

.btn.accordion-claim {
  position: relative;
  padding-left: 12px !important;
  box-shadow: none !important;
  width: 140px;
  height: 20px;
}

.accordion-claim:not(.collapsed)::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  background-image: url("../img/minus-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}

.accordion-claim.collapsed::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  background-image: url("../img/plus-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}

.accordion-icon:not(.collapsed)::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 10px;
  height: 10px;
  background-image: url("../img/minus-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}

.accordion-icon.collapsed::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 10px;
  height: 10px;
  background-image: url("../img/plus-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}

.servicelinetab table {
  border: 0px solid #fff;
  margin: 0px !important;
}

.servicelinetab table thead tr th {
  padding-left: 10px !important;
  background-color: #a0c8cd;
}
.claim-summary-header table thead tr th {
  padding-left: 10px !important;
  background-color: #a0c8cd;
}
#grid-full-container .claim-summary-header table td {
  background: center;
}
#grid-full-container .input-group-text {
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
}
#grid-full-container .rightside-block .input-group-text {
  background-color: unset !important;
  border: unset !important;
}

.servicelinetd table tbody tr td {
  padding-left: 5px !important;
  vertical-align: middle;
}

.table > :not(caption) > * > * {
  padding: 0px 2px !important;
}

/* table th {
    padding: 0px 2px !important;
} */

table thead tr {
  font-size: 12px;
  background-color: #a0c8cd;
  color: #000;
}

table td {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
  vertical-align: bottom;
}
#grid-full-container table td {
  background-color: #cde3e5;
}
.info-log-table td,
.info-log-table th,
.history-log-table td,
.history-log-table th {
  padding: 10px 10px !important;
  vertical-align: middle;
  font-size: 14px;
}

.modal-custome-header {
  display: flex;
  justify-content: space-between;
}

.info-log-table tr {
  background-color: unset;
}

.totalserviceline835 {
  background-color: #cde3e5;
}

.totalserviceline835 div {
  color: #000;
  font-size: 12px;
  font-weight: 700;
}

.servicelinetd {
  border: none;
  padding: 0px !important;
  margin: 0px !important;
}

/* .table>:not(caption)>*>* {
    padding: 0px 0px !important;
} */

.table > :not(caption) > * > .table-padding {
  padding: 10px 10px 10px 10px !important;
}

.table .sub-table {
  max-height: 120px;
  display: inline-block;
  vertical-align: middle !important;
}

.table-bordered {
  overflow: auto;
}

/* Table Width */
.serialno {
  width: 37px;
}

.date {
  width: 188px;
}

.procedure {
  width: 261px;
}

.revcode {
  width: 74px;
}

.units {
  width: 56px;
}

.billed {
  width: 93px;
}

.discount {
  width: 93px;
}

.allowed {
  width: 93px;
}

.deduct {
  width: 93px;
}

.coins {
  width: 93px;
}

.copay {
  width: 93px;
}

.patres {
  width: 93px;
}

.adjustments {
  width: 288px;
}

.cob {
  width: 93px;
}

.paid {
  width: 93px;
}

.balance {
  width: 93px;
}

.serialno-th {
  width: 40px;
}

.date-th {
  width: 188px;
}

.procedure-th {
  width: 261px;
}

.revcode-th {
  width: 74px;
}

.units-th {
  width: 56px;
}

.billed-th {
  width: 93px;
}

.discount-th {
  width: 93px;
}

.allowed-th {
  width: 93px;
}

.deduct-th {
  width: 93px;
}

.coins-th {
  width: 93px;
}

.copay-th {
  width: 93px;
}

.patres-th {
  width: 93px;
}

.adjustments-th {
  width: 288px;
}

.cob-th {
  width: 93px;
}

.paid-th {
  width: 93px;
}

.balance-th {
  width: 93px;
}

.em {
  width: 3%;
}

.em-td {
  width: 0%;
}
/* Quick Calculator */
.quick-calc {
  display: flex;
  gap: 20px;
  text-transform: uppercase;
  padding: 2px 10px 2px;
  justify-content: space-between;
  background-color: #6ba2b6 !important;
}

.power-off a,
.power-off a:hover {
  color: #fff;
}

.equalto {
  font-weight: 900;
}

.quickcalc-btn {
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 170px;
  height: 18px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 900;
}

.quickcalcResult {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

/* Modal Overlap */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999;
}

.date-pick-grid {
  height: 12px;
}

.search-btn {
  border: none;
  background-color: #e9ecef;
}

.modal-90w {
  --bs-modal-width: 90% !important;
}

.mdl-content-bg {
  background-color: #dae2e6 !important;
}

.form-control-solid-sm {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  /* border-radius: 6px !important; */
  color: #5e6278 !important;
  transition: color 0.2s ease !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
}

.form-control-solid-sm:focus {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  color: #5e6278 !important;
  transition: color 0.2s ease !important;
  box-shadow: 0 0 0 2.25px rgb(107 162 182) !important;
}

.wrapper-plb {
  width: 14.5%;
}

.border-top-bottom-dark-2 {
  width: 100%;
  margin-top: 15px !important;
  border-width: 1.5px !important;
  border-top: 5px solid black;
  border-bottom: 5px solid black;
  text-align: center;
}

.table-padding {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.search-grid {
  background-color: #6ba2b6;
  position: fixed;
  width: 142%;
  z-index: 9;
}

.height-claims {
  margin-top: 50px;
  position: fixed;
  width: 143%;
  overflow: hidden;
  height: 325px;
}

.form-control-search {
  height: 30px !important;
}

.form-control {
  padding: 0px 5px 0px 5px !important;
  color: #000 !important;
}

.width-100 {
  width: 100% !important;
}

.item-width-full {
  background-color: #99c7d7 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid white !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.item-width-full-pc {
  background-color: #99c7d7 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid white !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.w-55px {
  width: 10% !important;
}

.w-110px {
  width: 20% !important;
}

.w-desc {
  width: 42% !important;
}

.w-5 {
  width: 8% !important;
}

/* .pc-height {
  height: 250px !important;
  max-height: 500px !important;
  overflow-y: scroll !important;
} */
.pc-height {
  /* height: 250px !important; */
  max-height: 200px !important;
  overflow-y: scroll !important;
}

.item-width-full-servicelines {
  background-color: #99c7d7 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid white !important;
  border-radius: 0px !important;
}

.option-item {
  /* background-color: none !important; */

  background-color: #99c7d7 !important;
  color: black !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid #fff !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.width-check {
  width: 30px !important;
}

.width-label {
  width: 430px !important;
}

.width-common-btn {
  width: 150px !important;
}

.width-user-btn {
  width: 200px !important;
}

.option-item-assign {
  background-color: none !important;

  /* background-color: #e1e2e2 !important; */
  color: black !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid #99c7d7 !important;
  border-radius: 0px !important;
  /* width: 100% !important; */
}

.option-item-info {
  background-color: none !important;

  /* background-color: #e1e2e2 !important; */
  color: black !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid #99c7d7 !important;
  border-radius: 0px !important;
  width: 100% !important;
}

/* .option-item-assign {
    height: 40px;
} */

.option-item-pc {
  background-color: #a0c8cd !important;
  /* background-color: #e1e2e2 !important; */
  color: black !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid white !important;
  border-radius: 0px !important;
}

.option-item-service {
  background-color: #a0c8cd !important;
  /* background-color: #e1e2e2 !important; */
  color: black !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid white !important;
  border-radius: 0px !important;
  /* width: auto !important; */
}

.option-item-null {
  background-color: #b4bebf !important;
  /* background-color: #e1e2e2 !important; */
  color: black !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 0.5px solid white !important;
  border-radius: 0px !important;
  inline-size: 100% !important;
}

.date-service {
  inline-size: 85px !important;
}

.date-service-to {
  inline-size: 94px !important;
}

#popover-contained {
  max-inline-size: 450px !important;
}

#pc-popover-pc {
  max-inline-size: 602px !important;
}

.pc-popover-pc {
  inline-size: 602px;
  block-size: auto;
  max-inline-size: 602px !important;
}

.pc-popover-assign-large {
  max-inline-size: 1200px !important;
  inline-size: 1200px;
  block-size: auto;
}

.pc-popover-assign {
  max-inline-size: 600px !important;
  inline-size: 600px;
  block-size: auto;
}

#pc-popover-popup {
  max-inline-size: 700px !important;
}

.assign-label-width {
  width: 300px !important;
}

.btn-user-assign {
  width: 180px !important;
}

.popover-additional-overflow {
  height: 500px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.pc-popover-popup {
  inline-size: 700px;
  block-size: auto;
  max-inline-size: 700px !important;
}

.proc-service {
  width: 111px !important;
}

.charge-service {
  width: 103px !important;
}

.check-width {
  width: 47px !important;
}

.units-service {
  width: 60px !important;
}

.active-payer li {
  background-color: rgb(255, 255, 255) !important;
}

.active-payee li {
  background-color: rgb(255, 255, 255) !important;
}

.active-mouse-payer li {
  background-color: rgb(184, 198, 249) !important;
}

.active-mouse-payee li {
  background-color: rgb(184, 198, 249) !important;
}

.split-list-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.split-list-items:hover {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.split-list-items .list-actions span {
  cursor: pointer;
}

/* .rpv-thumbnail__list{
    transition: transform .2s; 
}
.rpv-thumbnail__list:hover{
    transform: scale(1.5); 
} */
.swich-icon-continer {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.swich-icon-continer .list-grid {
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}

.swich-icon-continer span:hover {
  background-color: #6c757d !important;
  cursor: pointer;
  color: #fff;
}

.swich-icon-continer .common-button-class-icon:hover {
  background-color: #6c757d !important;
}

.check-details-modal .modal-bottom-box input:hover {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  color: #fff;
}

.modal-body {
  padding-left: 1rem !important;
  padding-bottom: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0px !important;
}

.swich-icon-continer span i {
  font-size: 20px;
}

/* remove PDF scroll */
/* .rpv-core__inner-pages--vertical{
    overflow: unset !important;
} */
div.rpv-thumbnail__list-container .rpv-core__icon {
  stroke: #6a6a6a !important;
}

.all-check-submit-button {
  background: rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 5px;
}

.magnifier-container {
  width: 200px;
  height: 200px;
  background-color: rgb(255, 255, 255) !important;
}

.rightside-block.first-page-btn {
  background-color: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

.pagination-btn:focus {
  box-shadow: none;
}

.pagination-btn:disabled {
  box-shadow: none;
  border: none;
  outline: none;
}

.bg-disabled {
  background-color: #dfdfdf !important;
  color: #e3e3e3 !important;
}

.disabled-typography {
  color: #838383 !important;
}

.h-search {
  height: 380px !important;
}

.qms-btn .btn {
  border-color: #6ba2b6;
  padding: 2px 4px 2px;
}

.qms-btn-internal .btn {
  border-color: #000000;
  padding: 2px 4px 2px;
}

.qms-btn .btn:hover {
  background-color: #6ba2b6;
}

.qms-btn .btn :focus {
  box-shadow: none !important;
  background-color: #6ba2b6 !important;
}

.qms-btn .btn :active {
  box-shadow: none;
  background-color: #6ba2b6;
}

.manual-tat .btn-close {
  font-size: 45px;
}

.manual-tat input {
  border-radius: 10px !important;
  height: 34px;
}

.manual-tat input:focus {
  box-shadow: none;
  border-color: #6ba2b6;
}

.assign select {
  border-radius: 10px !important;
  height: 34px;
}

.assign select:focus {
  box-shadow: none;
  border-color: #6ba2b6;
}

.manual-tat .react-datetime-picker__wrapper {
  border-radius: 10px;
}

.assign .btn-close {
  margin-top: -30px !important;
  font-size: 30px;
}

.not-selected-modal {
  position: absolute;
  z-index: 2;
  top: -5px;
  left: 0px;
  padding: 10px;
  /* height: 160px; */
  width: 250px;
  background-color: #fff;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.check-details-modal {
  position: absolute;
  z-index: 4;
  top: -5px;
  left: 0px;
  padding: 10px;
  /* height: 160px; */
  width: 255px;
  background-color: #fff;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

div.check-modal-text-box {
  display: flex;
  flex-direction: column;
}

div.check-details-modal .check-modal-text-box {
}

.check-details-modal .modal-bottom-box {
  display: flex;
  justify-content: space-around;
  /* margin-top: 10px; */
}

.check-details-modal .check-modal-text-box input {
  margin-bottom: 10px;
}

.check-details-modal .modal-bottom-box span {
  display: block;
  padding: 5px;
  border-radius: 10px;
}

.check-details-modal .modal-bottom-box input {
  display: block !important;
  padding: 5px !important;
  border-radius: 10px !important;
  border: unset;
}

.not-selected-modal h4 {
}

.not-selected-modal .modal-bottom-box {
  display: flex;
  justify-content: space-around;
}

.not-selected-modal .modal-bottom-box span {
  display: block;
  padding: 5px;
  border-radius: 10px;
}

.modal-top-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-top-box i {
  font-size: 60px;
}

span.rotate-icon-split {
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px 12px;
  border-radius: 0.25rem;
  cursor: pointer;
}

div.pdf-selected-list {
  overflow: scroll;
  height: 78vh;
}

div.bottom-tab-common {
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  background-color: #fff;
  margin-right: 10px;
  cursor: pointer;
}

div.bottom-tab-active {
  color: #fff;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  background-color: #6ba2b6;
  margin-right: 10px;
  cursor: pointer;
}

div.bottom-tab-active span {
  display: inline-block;
  color: #fff !important;
}

.btn-hidden-add {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn-hidden-add:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn-hidden-add:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.loading-component {
  z-index: 9999999;
}

.form-floating input {
  padding: 30px 12px 10px !important;
}

/* Magnifier Style */
.img-magnifier-container {
  /* position: relative; */
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  /* cursor: none; */
  cursor: pointer;
  /*Set the size of the magnifier glass:*/
  width: 100px;
  height: 100px;
  z-index: 9999999;
  top: 369px;
  left: 483px;
}

.pc-popover {
  width: 500px;
  height: auto;
  --bs-popover-max-width: 500px !important;
}

.form-date-control {
  background-color: #f5f8fa;
  border: none !important;
  outline: none;
  border-radius: 10px !important;
  color: #5e6278;
  transition: color 0.2s ease;
  /* height: 40px; */
  /* z-index: 99 !important; */
}

.form-date-control > .react-datetimerange-picker__wrapper {
  background-color: #f5f8fa;
  border: none !important;
  outline: none;
  border-radius: 10px !important;
  color: #5e6278;
  transition: color 0.2s ease;
  height: 40px;
}

.max-500 {
  max-width: 500px !important;
}

.w-500 {
  width: 500px !important;
}

.w-600 {
  width: 600px !important;
}

.w-700 {
  width: 700px !important;
}

.left-b-r-10 {
  border-radius: 10px 0px 0px 10px !important;
}

.right-b-r-10 {
  border-radius: 0px 10px 10px 0px !important;
}

.filter-btn-custom {
  border-radius: 6px 0px 0px 6px !important;
  height: 35px !important;
  border: 1px solid #d9d9d9 !important;
  border-right: 0px !important;
}

.w-200px {
  width: 200px !important;
}

.height-100 {
  height: 80vh;
}

.dropdown-overflow {
  max-height: 300px !important;
  height: auto !important;
  overflow-y: scroll !important;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll-bar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.select__value-container::-webkit-scrollbar {
  display: none;
}
.select__value-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.select__option--is-focused {
  background-color: rgb(222, 235, 255) !important;
}

.tab-fixed-header {
  position: fixed !important;
  background-color: #fff !important;
  height: 50px !important;
  width: 100%;
  box-shadow: -16px -40px 0px 0px rgb(255 255 255);
  z-index: 100 !important;
}

.fixed-header {
  position: fixed !important;
}

.overflow-scroll-body {
  overflow-y: scroll;
}

.pt-7 {
  padding-top: 80px !important;
}

.stretch-card {
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
}

.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}

.card.transparent {
  background: transparent;
}

.card.card-tale {
  background: #176c89 !important;
  color: #ffffff;
}

.card.card-tale-2 {
  background: #176c89 !important;
  color: #ffffff;
}

.card.card-tale-3 {
  background: #176c89 !important;
  color: #ffffff;
}

.card.card-tale-4 {
  background: #176c89 !important;
  color: #ffffff;
}

.card.card-tale:hover {
  background: #96b2fb;
}

.fs-30 {
  font-size: 30px;
}

.border-right {
  border-right: 1px solid white;
  margin-left: 50px;
}

.overflow-hidden {
  overflow-x: hidden !important;
}

.card.card-flush > .card-header {
  border-bottom: 0 !important;
}

.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 0 2.25rem;
  color: #fff;
  background-color: transparent;
  border-bottom: 1px solid #f4f4f4;
}

.bgi-size-cover {
  background-size: cover;
}

.bgi-position-x-center {
  background-position-x: center;
}

.bgi-position-y-top {
  background-position-y: top;
}

.bgi-no-repeat {
  background-repeat: no-repeat;
}

.h-250px {
  height: 250px !important;
}

.rounded {
  border-radius: 0.475rem !important;
}

.payer-sender-wrapper {
  width: 375px !important;
}

.custom-datatable {
  height: 78vh !important;
  overflow-y: scroll !important;
}

.p-common div nav {
  height: 46px !important;
  min-height: 0px !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.iyNteU {
  color: #000;
}

.confirm-wrapper {
  position: absolute;
  top: -26px;
  /* right: 0; */
  width: 100%;
}

.confirm-wrapper-delete-alert {
  position: absolute;
  /* top: -27px;
    right: -47px; */
  top: 3px;
  right: -46px;
  /* background-color: #6ba2b6; */
  padding: 2px;
  z-index: 2;
}

.confirm-wrapper-delete-alert span.confirm-button {
  background-color: #176c89;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 0.8em;
  margin-right: 3px;
  font-weight: bold;
  width: 30px;
  height: 22px;
  padding: 5px 20px 5px;
  color: white;
  border-radius: 5%;
}

.input-confirm {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  width: 210px !important;
  height: 18px !important;
  color: #000 !important;
  font-size: 12px !important;
}

.confirm-arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1da2a8;
  position: absolute;
  top: 22px;
  right: 50%;
  margin-right: -5px;
}

.scroll-claim {
  height: 285px;
  overflow-y: scroll;
}

.scroll-claim-pp {
  height: 214px;
  overflow-y: scroll;
}

.scroll-overflow-y {
  overflow-y: scroll;
  max-height: 150px;
}

.fixed-837-header {
  position: fixed !important;
  width: 100% !important;
}

.ex2 input {
  text-transform: uppercase !important;
}

.ex2 input::placeholder {
  text-transform: lowercase !important;
}

.null-width {
  inline-size: 47px !important;
}

.checked-servicelines {
  height: 20px !important;
  width: 20px !important;
}

.table-report-modelTitle {
  color: #000 !important;
  font-size: 25px !important;
}

.table-report-tr {
  /* padding-bottom: 15px; */
}

.table-report-badge {
  font-size: 13px !important;
  background-color: #6ba2b6 !important;

  /* margin: auto !important; */
  width: 185px !important;
}

.table-report-badgeWhite {
  background-color: #ffffff !important;
  color: #000 !important;

  width: 150px !important;
  font-size: 14px !important;
}

.table-report-patient-account-text {
  display: inline-block;
  padding-left: 8px;
  margin: 0px;
  font-size: 14px;
}

/* .table-report-tbody1:hover  .table-report-badge{
    background-color: white !important;
    color: #000;
    border: 1px solid #0397a7;
} */

.table-report-tr {
  text-align: left !important;
  /* padding-right: 490px !important; */
  font-size: 15px !important;
  font-weight: 700 !important;
}

.border-option-black {
  border: 1px solid #000;
}

.shape-circle-logged-in {
  background-color: green !important;
  border-radius: 50% !important;
  padding: 0.02px 0.2px !important;
  color: green !important;
}

/* .table-report-thead {
    text-align: center;
} */
.select-search {
  /* z-index: 99 !important; */
  background-color: hsl(204deg 33.33% 97.06%);
  margin-left: 0px !important;
}

.select-search-job-monitor {
  /* z-index: 99 !important; */
  margin-left: 0px !important;
  background-color: hsl(204deg 33.33% 97.06%);
}

.select-search-client {
  background-color: hsl(204deg 33.33% 97.06%);
}

.select-search-reassign {
  background-color: hsl(204deg 33.33% 97.06%);
  width: 413px !important;
}

.select-search-835-list {
  width: 400px;
  background-color: hsl(204deg 33.33% 97.06%);
  margin-left: 0px !important;
}

.select-search-facility {
  /* width: 300px; */
  background-color: hsl(204deg 33.33% 97.06%);
  margin-left: 0px !important;
}

.select-search-approve {
  z-index: 99 !important;
  width: 170px;
  background-color: hsl(204deg 33.33% 97.06%);
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #0fd205;
  border-radius: 50%;
  display: inline-block;
}

.from_date-picker {
  z-index: 999 !important;
}

.rs-picker-toggle-textbox {
  background-color: #f5f8fa !important;
}

.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background-color: #f5f8fa !important;
  color: #000;
}

.text-uppercase-text-area textarea {
  text-transform: uppercase !important;
}

.form-switch-toggle {
  padding-left: 3.5em !important;
  margin-bottom: 0px !important;
}

.css-1nmdiq5-menu {
  z-index: 6 !important;
}

.bottom-tabs {
  position: fixed !important;
  /* height: 75px; */
  bottom: 0 !important;
  background: transparent;
  max-width: 1900px !important;
  overflow-x: scroll !important;
  width: 2000px !important;
  /* border: 1px solid #efefef; */
  border-radius: 5%;
}

.bottom-tabs::-webkit-scrollbar {
  height: 8px;
}

.btn-outline-dark:hover {
  background-color: #f3f3f3 !important;
  color: #000 !important;
}

.border-xtract {
  border-color: #1da2a8 !important;
}

.main-bottom-total-row {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
  background-color: rgb(255, 255, 255);
  min-height: 52px;
  border-bottom: 1px solid rgb(244, 244, 244);
  padding-left: 50px;
}

.sub-bottom-total-row {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 5px;
  padding-right: 5px;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 800;
  opacity: 1;
  min-height: 10px;
  padding: 0px;
}

.inner-bottom-total-row {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: inherit;
  justify-content: inherit;
  height: 100%;
  width: 100%;
  outline: none;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
}

.none-div-class {
  display: none !important;
}

.display-div-class {
  display: flex !important;
}

.jobmonitor-select {
  min-width: 270px;
  max-width: 270px;
  /* z-index: 99; */
}

.jobmonitor-select-tat {
  width: 210px;
}

.jobmonitor-datepicker {
  position: relative;
  z-index: 1;
  height: 40px;
}

.grid-job-monitor-select {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* width: 1200px;
    min-width: 1200px;
    max-width: 1200px; */
  gap: 20px;
  margin-bottom: 20px;
}

.grid-job-monitor-input {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* .sc-eDDNvR sc-jTrPJq sc-gLDzan iXrvRg Iglma dEPDEW rdt_TableCell{

} */
.transaction-collapseThree {
  overflow-y: scroll;
  height: 45vh;
}

.transaction-collapseThree {
  overflow-y: scroll;
  height: 45vh;
}

.timeZone-select {
  max-width: 220px !important;
  min-width: 220px !important;
}

.grid-customization-grid {
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.d-grid-additional-info {
  display: grid;
  justify-items: start;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.grid-customization-grid-output {
  display: grid;
  grid-template-columns: 24.3% 49.3% 25.3%;
  /* grid-template-columns: minmax(100px, max-content) repeat(auto-fill, 200px) 20%; */
  /* grid-template-columns: repeat(4, 1fr); */
  gap: 10px;
}

.grid-customization-grid-835 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.facility-addImageFiles-password {
  margin-top: 0px !important;
}

.claims-delete-add-btn {
  margin-top: 29px !important;
}

/* 
.select__control {
    height: 30px !important;
}

.select__control:focus {
    height: auto !important;
    min-height: auto !important;
} */

/* .css-3w2yfm-ValueContainer {
    height: auto !important;
}

.css-3w2yfm-ValueContainer:focus {
    height: auto !important;
} */

.report-td-style-defulat {
  word-wrap: break-word !important;
  width: 240px !important;
}

.lockbox-search-select {
  margin-left: 0px !important;
}

.fontSize-style::placeholder {
  font-size: 17px !important;
  vertical-align: middle;
}

.react-__placeholder {
  font-size: 17px !important;
}

.fileManagePadding {
  padding: 2px 8px !important;
}

.border-radius-password {
  border-radius: 10px 0px 0px 10px !important;
}

.border-radius-eye {
  border-radius: 0px 10px 10px 0px !important;
  height: 40px;
}

.dbk-grid-repeat {
  display: grid;
  grid-template-columns: repeat(auto-fill, 275px);
  justify-items: start;
  align-items: center;
  justify-content: start;
  align-content: center;
}

.clientReport-facility {
  padding: 10px 0px !important;
}

.width-header {
  width: 200px !important;
}

.width-header-pan {
  width: 170px !important;
}

.width-header-pfn {
  width: 190px !important;
}

.width-header-pmn {
  width: 140px !important;
}

.width-header-pln {
  width: 180px !important;
}

.width-header-identifier {
  width: 140px !important;
}

.width-header-payer-name {
  width: 240px !important;
}

.width-header-insurance-type {
  width: 110px !important;
}

.width-header-tba {
  width: 140px !important;
}

.patient-ul-scroll {
  position: fixed !important;
  z-index: 99999 !important;
  /* height: 285px; */
  overflow-y: auto !important;
  width: 1864px !important;
}

.payer-ul-scroll {
  position: fixed !important;
  z-index: 99999 !important;
  margin-top: -25px;
  /* height: 285px; */
  overflow-y: scroll !important;
  width: 1884px !important;
}

.payerLite-ul-scroll {
  position: fixed !important;
  z-index: 99999 !important;
  margin-top: -25px;
  /* height: 285px; */
  overflow-y: scroll !important;
  width: 1882px !important;
}

.repassword-input {
  padding-right: 35px !important;
}

.repassword-label {
  margin-bottom: 0px !important;
}

.custom-confirm-button-class {
  background-color: #6ba2b6 !important;
  color: white !important;
  text-decoration: none !important;
  border: 1px solid #6ba2b6 !important;
  box-shadow: none !important;
}

.custom-cancel-button-class {
  background-color: #6c757d !important;
  color: white !important;
  border: 1px solid #6c757d !important;
  border-radius: 9px !important;
  width: 70px;
}

.height-subjobs {
  height: 80px;
  overflow-y: scroll;
}

.bg_wrapper {
  padding: 60px 60px 60px 60px;
}

.font-size-h1 {
  font-size: 24px !important;
  text-align: center;
}

.form-group {
  margin-bottom: 1.75rem;
}

.font-size-p {
  color: #b5b5c3 !important;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.login_form {
  display: flex;
  height: 100vh;
}

.login_form_leftwrapper {
  /* background-image: url('../img/bg-4.jpg'); */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: 100vh;
  /* box-shadow: 0 0 20px rgba(1, 41, 112, .08); */
}

.login_form_leftwrapper img {
  width: 100%;
  height: 280px;
  object-fit: contain;
}

.copy_right {
  position: absolute;
  bottom: 40px;
  color: #1ba1a7;
  font-size: 14px;
  font-weight: 600;
}

.copy_right .link_datablocks:active {
  text-decoration: none;
}

.copy_right .link_datablocks:focus {
  text-decoration: none;
}

.link_datablocks {
  color: #1ba1a7;
  font-size: 14px;
  font-weight: 600;
}

.login_form_rightwrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 60px 60px 60px 60px;
}

.synced_data {
  flex-direction: column;
  width: 150px;
  font-size: 14px;
  color: #383897;
  padding: 4px 6px 6px 6px;
  border-radius: 8px;
}

.login-footer-position {
  bottom: 10px;
  left: 10px;
  position: fixed;
}

.loginLabel {
  color: #176c89;
  font-size: 28px;
  font-weight: 700 !important;
  line-height: 45px !important;
  margin-bottom: 0 !important;
}

.custom-confirm-button-class {
  background-color: #6ba2b6 !important;
  color: white !important;
  text-decoration: none !important;
  border: 1px solid #6ba2b6 !important;
  box-shadow: none !important;
}

.custom-cancel-button-class {
  background-color: #6c757d !important;
  color: white !important;
  border: 1px solid #6c757d !important;
  border-radius: 9px !important;
  width: 70px;
}

.swal-titleText {
  font-size: 25px !important;
}

.custom-daterange {
  min-width: 200px;
}

.invoice-report-date.error .rs-picker-default .rs-picker-toggle {
  border: 1px solid #ff0000 !important;
}
.apexcharts-xaxis-label {
  max-width: 50px !important;
}

.graph-width {
  min-width: "100%" !important;
}
