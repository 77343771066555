@font-face {
  font-family: "Calibri";
  src: url(../font/Calibri.eot);
  src: url(../font/Calibri.eot?#iefix) format("embedded-opentype"),
    url(../font/Calibri.woff) format("woff"),
    url(../font/Calibri.woff2) format("woff2"),
    url(../font/Calibri.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* .logo {
    padding: 20px 20px 60px 20px;
} */

/* .logo img {
    max-height: 50px;
    margin-right: 6px
} */
.mb-lg-17 {
  margin-bottom: 2.25rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  font-family: "Calibri";
}

.header {
  transition: 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 20px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 1px solid rgba(1, 41, 112, 0.2);
  font-size: 14px;
  color: #012970;
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: 0;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: 0 0;
}

.header .search-form button i,
.header-nav .nav-profile {
  color: #012970;
}

.header-nav ul {
  list-style: none;
}

.header-nav > ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .badge-number {
  position: absolute;
  inset: -10px -10px auto auto;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .messages .message-item p,
.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover,
.header-nav .notifications .notification-item:hover,
.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages .message-item {
  padding: 5px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
  text-decoration: none;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444;
}

.pagetitle,
.pagetitle h1 {
  margin-bottom: 0;
}

.header-nav .profile {
  min-width: 280px;
  padding-bottom: 0;
  inset: 11px -15px auto auto !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 12px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.sidebar {
  /* position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: .3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent */
  position: fixed;
  z-index: 100;
  overflow: hidden;
  width: 250px;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #e4e6ef;
  padding: 6px 6px 6px 6px;
  transition: 0.8s;
  z-index: 996;
}

.sidebar .aside-logo {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  align-items: center;
  padding: 20px 0px 0px 10px;
}

.sidebar .aside-logo .logo_main img {
  width: 120px;
  height: 50px;
  object-fit: contain;
}

.sidebar .aside-logo .logo_x img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.aside-main {
  position: relative;
}

.aside-footer {
  position: absolute;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  padding: 2px 12px 2px 2px;
}

.aside-footer .nav-item {
  list-style: none;
}

.aside-footer .nav-link:active {
  text-decoration: none;
}

.aside-footer .nav-link:focus {
  text-decoration: none;
}

.aside-footer .nav-content {
  list-style: none;
  padding-left: 12px;
  padding-top: 4px;
}

.aside-footer .log_out_btn {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #012970;
  font-size: 14px;
  font-weight: 600;
}

.aside-footer .icon_logout {
  color: #012970;
  font-size: 16px;
  font-weight: 600;
  margin-right: 13px;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

/* .sidebar-nav .nav-link,
.sidebar-nav .nav-link.collapsed {
    background: #fff;
    color: #6ba2b6
} */
.sidebar-nav .nav-item a:active {
  background: #fff;
  color: #6ba2b6;
  text-decoration: none;
}

.sidebar-nav .nav-item a:focus {
  background: #fff;
  color: #6ba2b6;
  text-decoration: none;
}

.sidebar-nav .nav-item a:hover {
  background: #fff;
  color: #012970;
  text-decoration: none;
}

.sidebar-nav .nav-item .nav-link span {
  color: #012970;
  font-size: 14px;
}

@media (max-width: 1400px) {
  /* .section {
        width: calc(100% - 80px) !important;
    } */
}

@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(1, 41, 112, 0.1);
    background: #fff;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }

  .sidebar {
    left: -300px;
  }

  .toggle-sidebar .sidebar {
    left: -180px;
  }
  .sidebar .aside-logo {
    justify-content: right;
  }
}

@media (min-width: 1200px) {
  /* .logo {
        width: 280px
    } */

  #main {
    margin-left: 260px;
  }

  #footer {
    width: 100%;
    height: 100vh;
  }

  .toggle-sidebar #footer,
  .toggle-sidebar #main {
    margin-left: 76px;
  }

  .toggle-sidebar .sidebar {
    left: -174px;
  }

  .toggle-sidebar .sidebar .aside-logo {
    margin-left: 34px;
  }
}

.toggle-sidebar .sidebar:hover .expander,
.toggle-sidebar .sidebar:hover {
  left: 0;
  transition: 0.8s;
  width: 264px;
}

.logo_x {
  transition: opacity 0.3s ease;
  /* Add transition property */
}

.logo_x.hidden {
  opacity: 0;
  pointer-events: none;
  display: none;
}

/* .toggle-sidebar .sidebar .expander:hover{
    left: 0;
    transition: 0.8s;
    width: 264px;

} */

.sidebar-nav,
.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 4px;
  color: #012970;
}

.sidebar-nav .nav-link:active {
  text-decoration: none;
}

.sidebar-nav .nav-link:focus {
  color: #012970;
  text-decoration: none;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 13px;
  color: #012970;
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #012970;
  background: #f6f9ff;
}

.sidebar-nav .nav-link.active {
  color: #6ba2b6;
  background: #f6f9ff;
}

.sidebar-nav .nav-content .nav-link.active {
  color: #6ba2b6;
  background: none;
}

.sidebar-nav .nav-link.active i {
  color: #012970;
}

.sidebar-nav .nav-link:hover i {
  color: #012970;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #6ba2b6;
  padding: 10px 44px;
  transition: 0.3s;
  text-decoration: none;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a.active,
.sidebar-nav .nav-content a:hover {
  color: #012970 !important;
}

.sidebar-nav .nav-content a.active i {
  background-color: #6ba2b6;
}

.side_menus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: baseline;
  gap: 220px;
}

.dropdown-menu .dropdown-footer,
.dropdown-menu .dropdown-header {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-footer {
  text-align: center;
}

.dropdown-footer a {
  color: #000;
  text-decoration: none;
  text-align: center;
}

#main {
  /* margin-top: 60px; */
  padding: 2px 2px;
  transition: 0.3s;
}

#main .css-m4dny8-container {
  width: 190px;
  font-size: 12px;
}
#main .small-select-container .css-m4dny8-container {
  width: 125px;
  font-size: 12px;
}
#main .small-select-station .css-m4dny8-container {
  width: 152px;
  font-size: 12px;
}
#main .dashboard-data-list .css-m4dny8-container {
  width: 100%;
  font-size: 12px;
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}

.pagetitle h1 {
  font-size: 24px;
  font-weight: 600;
  color: #012970;
}
