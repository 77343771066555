@font-face {
  font-family: "Calibri";
  src: url(./assets/font/Calibri.eot);
  src: url(./assets/font/Calibri.eot?#iefix) format("embedded-opentype"),
    url(./assets/font/Calibri.woff) format("woff"),
    url(./assets/font/Calibri.woff2) format("woff2"),
    url(./assets/font/Calibri.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  font-family: "Calibri";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Calibri";
}
